.nutrition-block-container {
    display: flex; /* Align items horizontally */
    flex-wrap: wrap; /* Ensure blocks wrap on smaller screens */
    justify-content: space-between; /* Add spacing between items */
    align-items: center; /* Ensure vertical alignment */
    gap: 20px; /* Adds spacing between blocks */
    margin: 70px 5px 60px 5px; /* Add ?px margin above the container */
    padding: 30px 30px 10px 30px; /* Add padding for spacing */
    background: #ffffff1a; /* Subtle overlay */
    border-radius: 12px; /* Soften edges */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add depth */
  }
  
  .nutrition-block {
    flex: 1 1 calc(20% - 10px); /* Adjust width dynamically with gap */
    min-width: 210px;
    max-width: 300px;
    height: 400px; /* Adapt height to content */
    text-align: center;
    color: white;
    padding: 20px;
    margin-bottom: 20px;
    border: 3px solid #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.7); /* Add a slight dark overlay for contrast */
    display: flex;
    flex-direction: column; /* Stack content vertically inside the block */
    justify-content: space-between; /* Evenly space content */
    align-items: center; /* Center align text horizontally */
  }

  @media (max-width: 768px) {
    .nutrition-block-container {
      flex-direction: column; /* Stack items vertically */
      gap: 15px; /* Maintain spacing between blocks */
      padding: 10px 10px; /* Adjust padding for smaller screens */
      margin: 0 auto; /* Center container within section */
      width: 100%; /* Ensure it fills the section width */
      max-width: none; /* Remove width constraints for small screens */
    }
  
    .nutrition-block {
      flex: 1 1 100%; /* Allow full width for blocks */
      max-width: 100%; /* Prevent fixed width constraints */
      height: auto; /* Let height adjust dynamically */
    }
  }

  @media (max-width: 768px) {
    section#nutrition {
      position: relative; /* Ensure content stays within bounds */
      padding-top: 20px;
      padding-bottom: 20px;
      margin: 0 auto;
      overflow: visible; /* Allow content expansion */
      min-height: auto; /* Allow height to adjust dynamically */
    }
  }

  section#nutrition {
    position: relative; /* Ensure it contains its content */
    padding-top: 20px; /* Add spacing to prevent overlap */
    padding-bottom: 20px;
    overflow: hidden; /* Prevent content from spilling outside */
    margin: 0 auto; /* Center the section */
  }
  
  .block button {
    margin-top: 20px;
  }
  
  .booking-form-container {
    background-color: #f9f9f9;
    color: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
  }
  
  .booking-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .booking-form-container form div {
    margin-bottom: 15px;
  }
  
  .booking-form-container label {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  
  .booking-form-container input,
  .booking-form-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }
  
  .booking-form-container button {
    flex: 1;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #00cc99;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .booking-form-container button:hover {
    background-color: #009977;
  }