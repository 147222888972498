/* General Button Styles */
button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color .3s;
  color: white;
}

/* Unique Button Styles for Sections */

/* Header Button */
#headerBtn {
  background-color: #00cc99;
}

#headerBtn:hover {
  background-color: #0056b3;
}

/* Join Us Button */
#joinUsBtn {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color .3s;
  color: white;
  background: linear-gradient(145deg, #3E4248, #2B2F34); /* Gunmetal gradient */
  border: none;
  border-radius: 10px;
  display: block;
  margin: 0 auto; /* Centers the button horizontally */
  transition: transform 0.2s, background-color 0.2s; /* Smooth transition for hover effects */
}

#joinUsBtn:hover {
  background: linear-gradient(145deg, #2B2F34, #3E4248); /* Reverse gradient */
  transform: scale(1.05); /* Slight zoom on hover */
}

.center-button a {
  color: white;
}

#contact-form-btn {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color .3s;
  color: white;
  border-radius: 7px;
  max-width: 100px;
  margin-left: 12px;
  background: linear-gradient(145deg, #3E4248, #2B2F34); /* Gunmetal gradient */

}

#contact-form-btn:hover {
  background: linear-gradient(145deg, #2B2F34, #3E4248); /* Reverse gradient */
  transform: scale(1.05); /* Slight zoom on hover */
}

#glowing-button {
  background-color: #00FFFF; /* Neon Aqua */
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 30px #00FFFF;
  animation: glowAqua 2s infinite; /* Infinite glowing animation */
  transition: transform 0.3s ease-in-out; /* Adds a subtle hover effect */
}

#glowing-button:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

@keyframes glowAqua {
  0%, 100% {
    box-shadow: 0 0 5px #00FFFF, 0 0 15px #00FFFF, 0 0 30px #00FFFF;
  }
  50% {
    box-shadow: 0 0 10px #00FFFF, 0 0 20px #00FFFF, 0 0 40px #00FFFF;
  }
}

#color-changing-button {
  background: linear-gradient(145deg, #00FFFF, #007FFF); /* Initial Aqua-Blue gradient */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 1em;
  border-radius: 10px;
  cursor: pointer;
  transition: background 1s ease, box-shadow 0.5s ease; /* Smooth gradient & shadow transitions */
  animation: colorChangeGlow 8s infinite alternate; /* Gradient and glow effect */
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.8); /* Initial glow effect */
}

@keyframes colorChangeGlow {
  0% {
    background: linear-gradient(145deg, #00FFFF, #007FFF); /* Aqua-Blue */
    box-shadow: 0 0 20px rgba(0, 255, 255, 1); /* Bright Aqua glow */
  }
  25% {
    background: linear-gradient(145deg, #FF69B4, #FF4500); /* Flamingo-Coral */
    box-shadow: 0 0 20px rgba(255, 105, 180, 1); /* Pink glow */
  }
  50% {
    background: linear-gradient(145deg, #FFBF00, #ADFF2F); /* Amber-Lime */
    box-shadow: 0 0 20px rgba(173, 255, 47, 1); /* Lime glow */
  }
  75% {
    background: linear-gradient(145deg, #8A2BE2, #DA70D6); /* Violet-Orchid */
    box-shadow: 0 0 20px rgba(138, 43, 226, 1); /* Violet glow */
  }
  100% {
    background: linear-gradient(145deg, #00FFFF, #007FFF); /* Back to Aqua-Blue */
    box-shadow: 0 0 20px rgba(0, 255, 255, 1); /* Aqua glow */
  }
}