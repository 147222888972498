/* Header & Nav */
#mainHeader {
  background-color: white;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 80px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

#logo-img {
  width: 85px;
  max-height: 80px;
  margin: 0;
  padding: 0;
}

nav ul {
  list-style: none;
  text-align: center;
  flex-wrap: wrap;
}

nav li {
  display: inline-block;
  flex: 1;
  margin: 0px 20px;
  position: relative; /* Required for dropdown positioning */
}

nav a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.3em;
}

nav a:hover {
  color: red;
}

.dropdown-content {
  display: none; /* Initially hidden */
  position: absolute;
  top: 100%; /* Position below the parent <li> */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  min-width: 160px;
  max-width: 200px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

nav li:hover .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
}

/* Dropdown pointer (upward-facing triangle) */
.dropdown-pointer {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 10px;
  background: #333;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.dropdown-content a {
  color: white;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-content a:hover, .dropdown-content a:focus {
  background-color: #555;
  color: #fff;
  outline: none;
}
