/* Reset & General */
html, body, h1, h2, p, button, a {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f4f4f4;
  font-family: "Book Antiqua", Palatino, "Palatino Linotype", serif;
  background-size: cover;
  background-position: center;
  background-color: grey;
  padding-bottom: 60px;
}

/* Typography */
h1, h2, h3, h4, h5, h6, p {
  font-family: "Book Antiqua", Palatino, "Palatino Linotype", serif;
  text-align: center;
}

h1 {
  font-size: 2.5em;
  margin-bottom: .5em;
}

h2 {
  font-size: 1.8em;
  margin-bottom: .5em;
}

p {
  font-size: 1.3em;
  line-height: 1.6;
  margin-bottom: 1em;
}

#mainContent {
  margin-top: 4px;
  margin-bottom: 0;
}

.contact {
  margin-top: 300px;
}
