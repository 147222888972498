@media only screen and (max-width: 768px) {
    .header-text h1 {
      font-size: 1.8em;
    }
  
    .banner {
      min-height: 250px;
    }
  
    nav ul {
      padding-top: 15px;
      flex-direction: column;
    }
  
    nav li {
      margin-bottom: 8px;
    }
  }

  @media only screen and (max-width: 480px) {
    .header-text h1 {
      font-size: 1.8em;
    }
    h2 {
      font-size: 1.4em;
    }
  
    p {
      font-size: 1em;
    }
  
    .banner {
      min-height: 200px;
    }
    #home-banner {
      padding-top: 200px;
    }
    #home-banner4 {
      padding-top: 200px;
      margin-top: 50px;
    }
  
    section {
      padding: 10px;
    }
  
    /* Popup scaling */
    .popup, .blur {
      width: 89%;
      max-width: none;
      min-width: none;
      padding: 20px;
      min-height: 270px;
    }
  
    .popup-content {
      width: 80%;
      max-width: 90%;
      min-height: 210px;
    }
  
    .popup-content h2 {
      font-size: 1.5em;
    }
  
    .popup-content h4 {
      font-size: 1em;
    }
  
    .popup-content p {
      font-size: 1.1em;
    }
  
    .popup-content .form-group input {
      width: 92%;
      padding: 0.8em;
      box-sizing: border-box;
      font-size: 0.8rem;
    }
  
    .popup-content .form-group {
      margin-bottom: 0.4rem;
    }
  
    button {
      padding: 7px 16px;
      font-size: 0.9em;
      cursor: pointer;
      transition: background-color .3s;
    }
    .about-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .profile-picture {
      width: 80%;
      max-height: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    
    .profile-picture img {
      width: 85%;
      height: 100%;
      object-fit: cover;
      border-radius: 35px 35px 0 35px;
      display: flex;
    }
    
    .profile-picture h1 {
      margin-top: 0.5rem;
      font-size: 1.5em;
      overflow: auto;
      display: block;
      text-align: center;
      justify-self: center;
    }
    
    .about-text {
      display: flex;
      width: 100%;
      padding: 20px;
      justify-content:stretch;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .container {
      padding: 5px;
    }
  
    .banner {
      padding: 20px;
    }
  }

  @media (max-width: 768px) {
    nav ul {
      flex-direction: column;
      gap: 10px;
    }
  
    header {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    form {
      padding: 15px;
    }
  }

  @media (max-width: 768px) {
    .mental-block, .nutrition-block {
      flex: 1 1 100%; /* Single column */
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    .about-section {
      flex-direction: column;
      text-align: center;
    }
    .about-section img {
        max-width: 80%;
      }
    }

    @media (max-width: 768px) {
        footer {
          padding: 15px;
        }
      }

      @media (max-width: 768px) {
        body {
          font-size: 14px;
        }
      
        h1 {
          font-size: 1.8rem;
        }
      
        h2 {
          font-size: 1.5rem;
        }
      
        h3 {
          font-size: 1.2rem;
        }
      }

      @media (max-width: 768px) {
        form {
          grid-template-columns: 1fr; /* Single-column layout */
        }
      
        form label {
          text-align: left;
          padding-right: 0;
        }
      
        form input, form textarea {
          grid-column: 1 / span 1;
        }
      }

      @media (max-width: 768px) {
        button {
          font-size: 0.9rem;
          padding: 10px;
          margin: 5px 0;
        }
      }


@media (max-width: 768px) {
    .mental-block, .nutrition-block {
      flex: 1 1 100%; /* Single column layout */
      max-width: 100%;
    }
  
    .about-section {
      flex-direction: column;
      text-align: center;
      gap: 10px;
    }
  }

  @media (max-width: 768px) {
    img {
      max-width: 90%; /* Reduce size on smaller screens */
    }
  }

  @media (max-width: 768px) {
    footer {
      flex-direction: column;
      text-align: center;
    }
  }