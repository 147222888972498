/* General Section Styling */
#intro-section, #closing-section {
  padding: 40px 20px;
  text-align: center;
}

#intro-section p, #closing-section p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.2em;
  line-height: 1.8;
}

/* Team Members Section */
#team-members-section {
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  gap: 5%; /* Adds a 5% gap between content and image container */
  background-color: #333;
}

.team-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 1200px;
}

/* 70% Team Members Container */
.team-members-container {
  flex: 0 0 68%; /* Occupies 70% of the width */
  display: flex;
  flex-direction: column;
  gap: 40px; /* Space between team members */
}

/* Individual Team Member Layout */
.team-member {
  display: flex;
  align-items: center;
  gap: 20px;
}

.team-member-reverse {
  flex-direction: row-reverse;
}

/* 25% Image Container */
.team-member-picture {
  flex: 0 0 22%; /* Occupies 25% of the width */
}

.team-member-picture img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Team Member Details */
.team-member-details {
  flex: 1;
  background-color: #444;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.team-member-details h1 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.team-member-details p {
  font-size: 1.1em;
  line-height: 1.6;
}

/* Image Container */
.image-container {
  flex: 0 0 25%; /* Occupies 25% of the width */
  display: flex;
  align-items: stretch;
}

.image-container img {
  width: 100%;
  min-height: 400px; /* Ensures the image has a minimum height */
  max-height: 1200px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}