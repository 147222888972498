.cookie-banner {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 400px;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .cookie-banner p {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .cookie-banner a {
    color: #00cc99;
    text-decoration: underline;
  }
  
  .cookie-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .cookie-buttons button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.85rem;
  }
  
  .cookie-buttons .accept {
    background-color: #00cc99;
    color: white;
  }
  
  .cookie-buttons .accept:hover {
    background-color: #009977;
  }
  
  .cookie-buttons .reject {
    background-color: #444;
    color: white;
  }
  
  .cookie-buttons .reject:hover {
    background-color: #666;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }