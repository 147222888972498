/* Footer */
footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(23, 21, 21);
  color: white;
  height: 60px;
  z-index: 3;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-nav li {
  margin: 0 10px;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}

.footer-nav a:hover {
  color: pink;
}

.copyright-div {
  padding: 10px;
  display: flex;
  align-items: baseline;
}

.copyright-div p {
  margin-bottom: 0;
  color: brown;
}