/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-overlay.hidden {
  display: none;
}

/* Popup Content */
.popup-content {
  background: rgba(255, 255, 255, 0.1); /* Slightly transparent white background */
  backdrop-filter: blur(10px); /* Adds blur effect */
  border-radius: 15px;
  padding: 30px;
  max-width: 400px;
  width: 90%;
  color: white; /* White text for dark background */
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
}

.close:hover {
  color: #ff4b5c; /* Red hover effect */
}

/* Form */
#joinForm {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5); /* White border */
  border-radius: 5px;
  background-color: transparent;
  color: white;
  font-size: 1rem;
}

.form-group input::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Placeholder color */
}
/* Alert */
#customAlertJoin {
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  color: white;
}