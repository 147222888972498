/* src/styles/PrivacyPolicy.css */
.privacy-policy-container {
    padding: 200px 20px 20px; /* Add padding at the top (80px) */
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .privacy-policy-container section {
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul {
    padding-left: 20px;
    list-style-type: disc;
  }

  .privacy-policy-container h1,
.privacy-policy-container h2 {
  scroll-margin-top: 80px; /* Offset scroll by 80px */
}